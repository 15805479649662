body {
    font-family: 'Roboto', sans-serif;
    background-image: url("../images/bg.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0px 0px;
    font-size: 14px;
    color: #026996;
}

.pageHeader {
    font-family: 'PT Sans', sans-serif;
}

h1 {
    color: #09adf4;
}

.top-buffer { 
    margin-bottom:20px; 
}

.shadow {
    box-shadow: -1px -1px 10px -2px rgba(3,15,92,0.79);
}

.panel-body {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #ffffff;
    border: 1px solid #d6d7d8;
}



.page-header {
    padding-bottom: 9px;
    margin: 30px 0 10px;
    border-bottom: 1px solid #eee;
    color: #09adf4;
    font-size: 2em;
    padding-top: 0px;
}

.subheader {
    font-size: 60%;
    opacity: 0.8;
}

.breadcrumb {
    background: linear-gradient(#09adf4, #5BC4F2);
    box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.5);
}
.breadcrumb>li {
    display: inline-block;
}

.breadcrumb a {
    color: #ffffff;
    text-decoration: none;
}
.breadcrumb > li + li:before {
    color: #ffffff;
    content: " \00bb\00a0 ";
}
.breadcrumb > .active {
    color: #ffe812;
}

.tile {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    margin-bottom: 10px;
    border: 1px solid #026996;
    border-radius: 8px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.5);
}

.tile-title {
    color: white;
    padding: 15px;
    background: linear-gradient(#09adf4, #5BC4F2);
    border-radius: 8px 8px 0px 0px;
    display: block;
    border-bottom: 1px solid #000000;
    padding-top: 10px;
    padding-bottom: 10px;
    
}

.tile-body {
    width: 100%;
    background: #fff;
    border-radius:  0px 0px 8px 8px;
    margin-top: 0px;
    padding-bottom: 5px;
    padding-top: 5px;

}

.tableheader {
    color: white;
    padding: 15px;
    font-size: 14px;
    font-weight: 100;
    background: linear-gradient(#09adf4, #5BC4F2);
    border-radius: 8px 8px 8px 8px;
}

.tableRow {
    color: #026996;
    padding: 15px;
    font-size: 14px;
    cursor: pointer;
    
}
